import { Toaster as Sonner } from 'sonner';
import { FiCheckCircle, FiSlash, FiAlertCircle } from 'react-icons/fi';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme={'light'}
      className="toaster group"
      icons={{
        success: <FiCheckCircle className="w-6 h-6" />,
        error: <FiSlash className="w-6 h-6" />,
        info: <FiAlertCircle className="w-6 h-6" />,
        warning: <FiAlertCircle className="w-6 h-6" />
      }}
      toastOptions={{
        unstyled: true,
        classNames: {
          toast:
            'group toast group-[.toaster]:shadow-lg p-4 flex justify-left items-center gap-3 rounded-lg !min-w-[320px] !min-h-[68px]',
          success: '!bg-[#12AA60] !text-white !border-[#12AA60]',
          error: '!bg-[#EB5757] !text-white !border-[#EB5757]',
          info: '!bg-[#2E92D7] !text-white !border-[#2E92D7]',
          warning: '!bg-[#FCA652] !text-white !border-[#FCA652]',
          title: 'text-base',
          description: 'text-sm text-white !font-[300]',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
          closeButton:
            '!top-4 !left-[calc(88%+1rem)] !text-white !bg-transparent !border-transparent !text-lg'
        }
      }}
      duration={5000}
      position="top-right"
      richColors
      closeButton
      {...props}
    />
  );
};

export { Toaster };
