import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

export default function Header() {
  const [state, setState] = useState({ isOpen: false });

  const { t } = useTranslation();

  return (
    <nav className={styles.header_nav}>
      <div
        className={`${styles.menu_btn} ${state.isOpen ? styles.open : null}`}
        onClick={() => setState({ isOpen: !state.isOpen })}
      >
        <span></span>
        <small>{t('menu')}</small>
      </div>
      <div className={styles.logo_nav}>
        <a href="https://www.broadcom.com/">Broadcom</a>
      </div>
      <div className={styles.menu_wrapper}>
        <ul
          className={`${styles.header_menu} ${
            state.isOpen ? styles.open : null
          }`}
        >
          <li>
            <a href="https://www.broadcom.com/products/">{t('products')}</a>
          </li>
          <li>
            <a href="https://www.broadcom.com/solutions/">{t('solutions')}</a>
          </li>
          <li>
            <a href="https://www.broadcom.com/support/">{t('supp_serv')}</a>
          </li>
          <li>
            <a href="https://www.broadcom.com/company/">{t('company')}</a>
          </li>
          <li>
            <a href="https://www.broadcom.com/how-to-buy/">{t('how_to_buy')}</a>
          </li>
          <li className={styles.login_nav}>
            <a href="https://www.broadcom.com/mybroadcom/login">{t('login')}</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
