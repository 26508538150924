'use client';
import 'styles/globals.scss';
import 'styles/tw-globals.scss';
import 'public/i18n/i18n';
import { EventsContextProvider } from 'context/EventsListingsProvider';
import { KnightsContextProvider } from 'context/KnightsListingsProvider';
import { OfferingsContextProvider } from 'context/OfferingsListingsProvider';
import { PartnersContextProvider } from 'context/PartnerListingsProvider';
import { UseCasesContextProvider } from 'context/UseCasesListingsProvider';
import { ReactNode, useEffect, useState } from 'react';
import { fixCookieStyles } from 'shared/cookies';
import MainHeader from 'components/organisms/MainHeader';
import MainFooter from 'components/organisms/MainFooter';
import { SessionProvider } from 'next-auth/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthContextProvider } from 'context/AuthProvider';
import { CSPListingsProvider } from 'context/CSPListingsProvider';
import { Toaster } from 'components/ui/toast';

const queryClient = new QueryClient();

export default function Providers({ children }: { children: ReactNode }) {
  fixCookieStyles();

  const [initialRenderComplete, setInitialRenderComplete] = useState(false);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  if (!initialRenderComplete) return <></>;

  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <PartnersContextProvider>
            <OfferingsContextProvider>
              <KnightsContextProvider>
                <CSPListingsProvider>
                  <EventsContextProvider>
                    <UseCasesContextProvider>
                      <div className="main_container">
                        <header className="main_header">
                          <MainHeader />
                        </header>
                        <main className="main_content">{children}</main>
                        <footer className="main_footer">
                          <MainFooter />
                        </footer>
                      </div>
                      <Toaster />
                    </UseCasesContextProvider>
                  </EventsContextProvider>
                </CSPListingsProvider>
              </KnightsContextProvider>
            </OfferingsContextProvider>
          </PartnersContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}
