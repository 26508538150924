import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import en_trans from '../locales/en/translation.json';
import cn_trans from '../locales/cn/translation.json';
import ja_trans from '../locales/ja/translation.json';
import pt_trans from '../locales/pt/translation.json';
import es_trans from '../locales/es/translation.json';

i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en_trans
      },
      es: {
        translation: es_trans
      },
      cn: {
        translation: cn_trans
      },
      ja: {
        translation: ja_trans
      },
      pt: {
        translation: pt_trans
      }
    }
  });

export default i18next;
