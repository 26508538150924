import { useRef } from 'react';

const safeDocument = typeof document !== 'undefined' ? document : {};

export default function useScroll() {
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  function blockScroll() {
    if (!body || !body.style || scrollBlocked.current) return;

    html.style.overflow = 'hidden';
    body.style.overflow = 'hidden';

    scrollBlocked.current = true;
  }

  function allowScroll() {
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.overflow = '';
    body.style.overflow = '';

    scrollBlocked.current = false;
  }

  return [blockScroll, allowScroll];
}
