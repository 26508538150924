import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

export default function AuthenticatedHeader() {
  const { t } = useTranslation();

  return (
    <nav className={`${styles.header_nav} ${styles.dashboard_nav}`}>
      <div className={styles.logo_nav}>
        <a href="https://www.broadcom.com/">Broadcom</a>
      </div>
      <span className={styles.partner_program_title}>
        {t('expert_program_title')}
      </span>
    </nav>
  );
}
