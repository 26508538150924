import useMount from '../hooks/useMount';
import { getBaseURL } from './utils';

function delay(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

// Fix the cookies styles overriding the site's
// The div ot-optout-icon was causing the site to reload the _semantics-css which was overriding the component's styles
export const fixCookieStyles = async () => {
  if (!getBaseURL()?.includes('expert.broadcom.com')) return;

  const isMounted = useMount();

  for (let i = 0; i < 10; i++) {
    const _doc = isMounted ? document : null;
    const element = _doc?.querySelector('.ot-optout-icon');

    if (element) {
      element?.parentNode?.removeChild(element);
      return;
    }

    await delay(200);
  }
};
