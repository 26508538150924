import { useSession } from 'next-auth/react';

import Loader from '../../atoms/Loader';
import AuthenticatedHeader from '../AuthenticatedHeader';
import Header from '../Header';
import styles from './style.module.scss';

export default function MainHeader() {
  const { data: session, status } = useSession();
  const loading = status === 'loading';

  return (
    <>
      {/* Page Loader */}
      {loading && <Loader />}
      <div className={`${styles.header_container} grid_container gutterless`}>
        {session ? <AuthenticatedHeader session={session} /> : <Header />}
      </div>
    </>
  );
}
