import styles from './style.module.scss';
import IconLinkedin from '../../../public/images/icon-linkedin.svg';
import IconTwitter from '../../../public/images/icon-twitter.svg';
import IconYoutube from '../../../public/images/icon-youtube.svg';
import { useTranslation } from 'react-i18next';

export default function MainFooter() {
  const { t } = useTranslation();

  return (
    <div className={`grid_container ${styles.main_footer}`}>
      <div className={styles.footer_left}>
        <ul className={styles.footer_menu}>
          <li>
            <a href="https://www.broadcom.com/products/">{t('products')}</a>
          </li>
          <li>
            <a href="https://www.broadcom.com/solutions/">{t('solutions')}</a>
          </li>
          <li>
            <a href="https://www.broadcom.com/support/">{t('supp_serv')}</a>
          </li>
          <li>
            <a href="https://www.broadcom.com/company/">{t('company')}</a>
          </li>
          <li>
            <a href="https://www.broadcom.com/how-to-buy/">{t('how_to_buy')}</a>
          </li>
        </ul>

        <div className={styles.copyright_text}>
          Copyright © 2005-{new Date().getFullYear()} Broadcom.{' '}
          <span className={styles.put_br_mobile} /> {t('all_rights')}
        </div>

        <ul className={styles.secondary_nav}>
          <li>
            <a href="https://www.broadcom.com/company/legal/privacy">
              {t('privacy')}
            </a>
          </li>
          <li>
            <a href="https://www.broadcom.com/company/citizenship/supplier-responsibility">
              {t('supplier_resp')}
            </a>
          </li>
          <li>
            <a href="https://www.broadcom.com/company/legal/terms-of-use">
              {t('terms_use')}
            </a>
          </li>
          <li>
            <a href="https://www.broadcom.com/sitemap">{t('sitemap')}</a>
          </li>
        </ul>
      </div>
      <div className={styles.footer_right}>
        <ul className={styles.social_nav}>
          <li>
            <a
              aria-label="Facebook"
              target="_blank"
              href="https://www.linkedin.com/company/broadcom"
            >
              <IconLinkedin />
            </a>
          </li>
          <li>
            <a
              aria-label="Twitter"
              target="_blank"
              href="https://twitter.com/Broadcom"
            >
              <IconTwitter />
            </a>
          </li>
          <li>
            <a
              aria-label="Youtube"
              target="_blank"
              href="https://www.youtube.com/user/BroadcomCorporation"
            >
              <IconYoutube />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
